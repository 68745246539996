import {
  FundPanelPage as IFundPanelPage,
  IPage,
  PageNavigationPanel as IPageNavigationPanel,
  StrategyPanelPage as IStrategyPanelPage,
} from '@/shared/MwsViewModels';
import { isFundPage, isNavigationPanel, isStrategyPage } from '../../types/typeguards';
import { safeArray } from '../../utils/helpers';
import { SelfAttestationData } from '../../lib/ModalSelfAttestation/index';

type IAttestationPanelPage = IStrategyPanelPage | IFundPanelPage;

interface AttestationLabels {
  description: string;
  accept: string;
  decline: string;
}

type INavigationPanel = IPageNavigationPanel | undefined;

const populateLabels = (navigationPanel: INavigationPanel) => {
  const labels: AttestationLabels = {
    description: navigationPanel?.labels?.SelfAttestationDescription || '',
    decline: navigationPanel?.labels?.SelfAttestationDecline || '',
    accept: navigationPanel?.labels?.SelfAttestationAccept || '',
  };

  return labels;
};

const provideLabelsFromDictionary = (pageData: IFundPanelPage) => {
  const panelGroups = pageData?.panelGroups;
  let navigationPanel: INavigationPanel = undefined;

  for (const panelGroup of panelGroups) {
    const panels = safeArray(panelGroup?.panels);
    const foundPanel = panels.find((panel) => isNavigationPanel(panel));

    if (foundPanel) {
      navigationPanel = foundPanel as IPageNavigationPanel;
    }
  }

  return populateLabels(navigationPanel);
};

export default function getSelfAttestationData(pageData: IPage): SelfAttestationData {
  if (isFundPage(pageData) || isStrategyPage(pageData)) {
    const panelPage: IAttestationPanelPage = pageData as IAttestationPanelPage;
    const labels = provideLabelsFromDictionary(panelPage);
    const breacrumbs = panelPage?.breadcrumbs;

    const [parentLink] = breacrumbs.slice(-2);

    const panelStrategyName = isStrategyPage(pageData)
      ? (panelPage as IStrategyPanelPage)
      : ({} as IStrategyPanelPage);

    return {
      showSelfAttestation: panelPage?.showSelfAttestation,
      acceptLabel: labels?.accept,
      declineLabel: labels?.decline,
      description: labels?.description,
      pageType: isFundPage(pageData) ? 'fund' : 'strategy',
      pageName: isFundPage(pageData) ? panelPage?.fund?.fundName : panelStrategyName?.strategyName,
      pageId: panelPage?.id,
      breadcrumbLink: parentLink,
    };
  } else {
    return null;
  }
}
