/* eslint max-lines: ["error", {"max": 250, "skipBlankLines": true}]*/
import { Component, Prop, Vue } from 'vue-property-decorator';
import { gsap } from 'gsap/dist/gsap';
import { FundLandingPageHeaderPanelNavigation, ILink, Image, ShareClass } from '@/shared/MwsViewModels';
import { throttle } from 'underscore';
import ClickOutside from 'vue-click-outside';
import template from './fund-banner.vue';
import { areColliding, getFullHeight } from '../../../../shared/helpers/dom-operations';
import { filterUniqueByPredicate } from '../../../../shared/helpers/collections';
import loadCldrPromise from '../../../../assets/scripts/utilities/intialise';
import { getCookieValue, setFundCookie } from '../../../../react/rebrand-lite/utils/helpers';
import EventBus from '../../../../shared/EventBus';

@Component({
  mixins: [template],
  directives: {
    ClickOutside,
  },
})
export default class FundBanner extends Vue {
  @Prop()
  background: Image;

  @Prop()
  heading: string;

  @Prop()
  navigation: FundLandingPageHeaderPanelNavigation[];

  @Prop()
  shareClasses: ShareClass[];

  @Prop()
  fundId: number;

  @Prop()
  fundSubscriptionLink: ILink;

  get shareClassOptions(): { value: ShareClass; label: string }[] {
    return (this.shareClasses || []).map((shareClass) => ({
      value: shareClass,
      label: `${shareClass.shareClass}-${shareClass.incAcc.slice(0, 3)} ${shareClass.currency} ${
        shareClass.mappingType === 'L' ? '- Longest' : ''
      }`,
    }));
  }

  get bannerStyle(): { [key: string]: string } {
    if (this.background) {
      return { backgroundImage: `url('${this.background.url}')` };
    }
  }

  public selectedShareClass: ShareClass = null;

  public selectedAnchorIdentifier: string | null = null;

  public selectedMobileAnchorIdentifier: string | null = null;

  private allAnchorElements: Element[] = [];

  public sectionSelectorOpen = false;

  public shrinkNavBar: boolean = false;

  public scrollDirectionDown: boolean = false;

  public saveFundButtonText: string = '';

  public iconColor: string = '';

  public newSelectedShareClassValue: string = null;

  private oldSelectedShareClass: ShareClass = null;

  private header: HTMLElement;

  private bgBack: HTMLElement;

  private bgFront: HTMLElement;

  private toolbar: HTMLElement;

  private topOfThePage: HTMLElement;

  private restOfThePage: HTMLElement;

  private requestId: number | null = null;

  private deltaHeight: number;

  private didScroll = true;

  private lastScrollTop = 0;

  private progress = 0;

  private reversed = true;

  private headerAnimation: gsap.core.Timeline;

  private shadowAnimation: gsap.core.Tween;

  mounted() {
    this.header = this.$refs.appHeader as HTMLElement;
    this.bgBack = this.$refs.backgroundBack as HTMLElement;
    this.bgFront = this.$refs.backgroundFront as HTMLElement;
    this.toolbar = this.$refs.smallToolbar as HTMLElement;

    this.topOfThePage = this.$refs.bgfundsPageNavigationWrapper as HTMLElement;
    this.restOfThePage = document.querySelector<HTMLElement>('.bgfunds-sidebar');
    this.allAnchorElements = Array.from(document.querySelectorAll('.fixed-anchor'));

    this.deltaHeight = this.header.offsetHeight - this.toolbar.offsetHeight;

    this.headerAnimation = this.createHeaderAnimation();
    this.shadowAnimation = this.createShadowAnimation();

    this.selectedShareClass =
      this.shareClassOptions.length > 0
        ? this.shareClassOptions.find((s) => s.value.mappingType === 'L')?.value ||
          this.shareClassOptions[0].value
        : null;
    this.oldSelectedShareClass = this.selectedShareClass;
    if (this.selectedShareClass) {
      this.$store.dispatch('setUcitShareClass', this.selectedShareClass);
    }

    this.update();
    window.addEventListener('scroll', throttle(this.highlightNavigation, 100));
    window.addEventListener('scroll', (): void => {
      this.didScroll = true;
      this.requestUpdate();
    });

    setInterval((): void => {
      if (this.didScroll) {
        this.hasScrolled();
        this.didScroll = false;
      }
    }, 250);

    document.addEventListener('DOMContentLoaded', () => {
      loadCldrPromise().done(() => {
        this.handleUrlHashWithNavigation();
      });
    });

    this.saveFundButtonText = this.isFundOnSaveFundList() ? 'Fund Saved' : 'Save Fund';
    this.iconColor = this.isFundOnSaveFundList() ? 'white' : '#231F20';

    EventBus.$on('new-share-class', (newShareClassValue) => {
      this.newSelectedShareClassValue = newShareClassValue;
      this.saveFundButtonText = this.isFundOnSaveFundList() ? 'Fund Saved' : 'Save Fund';
      this.iconColor = this.isFundOnSaveFundList() ? 'white' : '#231F20';
    });
  }

  get mobileNavigation(): FundLandingPageHeaderPanelNavigation[] {
    return filterUniqueByPredicate(this.navigation || [], (nav) => nav.hrefAnchor);
  }

  get mainNavigation(): FundLandingPageHeaderPanelNavigation[] {
    return this.mobileNavigation.filter((nav) => !nav.showOnMobileOnly);
  }

  get sortedMobileNavigation(): FundLandingPageHeaderPanelNavigation[] {
    var anchorsInMobileOrder = [];
    this.allAnchorElements.forEach((a) => {
      var matchingAnchor = this.mobileNavigation.find(
        (n) => a.firstElementChild.id === n.hrefAnchor
      );
      if (matchingAnchor) {
        if (!anchorsInMobileOrder.includes(matchingAnchor)) {
          anchorsInMobileOrder.push(matchingAnchor);
        }
      }
    });
    return anchorsInMobileOrder;
  }

  onNavigationItemClick(anchor: FundLandingPageHeaderPanelNavigation): void {
    this.$router.push({ hash: `#${this.cleanUrlHash(anchor.navigationText)}` });

    const panel = document.getElementById(anchor.hrefAnchor);
    if (panel != null) {
      gsap.to(window, { duration: 0.5, scrollTo: panel });
    } else {
      console.warn(`No element labeled #${anchor.hrefAnchor}.`);
    }
  }

  onNavigationBoxClick(): void {
    this.sectionSelectorOpen = !this.sectionSelectorOpen;
  }

  onNavigationBoxHide(): void {
    this.sectionSelectorOpen = false;
  }

  onShareClassChange(): void {
    if (this.oldSelectedShareClass === this.selectedShareClass) {
      return;
    }
    this.oldSelectedShareClass = this.selectedShareClass;
    this.saveFundButtonText = this.isFundOnSaveFundList() ? 'Fund Saved' : 'Save Fund';
    this.iconColor = this.isFundOnSaveFundList() ? 'white' : '#231F20';
    this.$emit('shareClassChanged', this.selectedShareClass);
  }

  onFundSubscribeClick(): void {
    if (this.fundSubscriptionLink) {
      this.$store.dispatch('openFundSubscriptionPopup');
      this.$store.dispatch('setFundSubscriptionPopupLink', this.fundSubscriptionLink.url);
    }
  }

  private requestUpdate(): void {
    if (!this.requestId) this.requestId = requestAnimationFrame(this.update);
  }

  onSaveButtonClick(): void {
    const newSavedShareClass = this.getShareClassCookieId(
      this.fundId,
      this.newSelectedShareClassValue
        ? this.newSelectedShareClassValue
        : this.selectedShareClass?.identifier
    );

    let savedShareClassIds = this.getSavedShareClasses();

    if (savedShareClassIds.includes(newSavedShareClass)) {
      savedShareClassIds = savedShareClassIds.filter((id) => id !== newSavedShareClass);
      this.saveFundButtonText = 'Save Fund';
      this.iconColor = '#231F20';
    } else {
      savedShareClassIds.push(newSavedShareClass);
      this.saveFundButtonText = 'Fund Saved';
      this.iconColor = 'white';
    }

    setFundCookie('BG_SAVED_FUNDS', savedShareClassIds.join(','), '/');
  }

  public isFundOnSaveFundList(): boolean {
    const savedShareClass = this.getShareClassCookieId(
      this.fundId,
      this.newSelectedShareClassValue
        ? this.newSelectedShareClassValue
        : this.selectedShareClass?.identifier
    );

    let savedShareClassIds = this.getSavedShareClasses();

    return savedShareClassIds.includes(savedShareClass);
  }

  getSavedShareClasses = (): string[] => {
    const cookieValue = getCookieValue('BG_SAVED_FUNDS');
    return cookieValue && cookieValue !== '' ? cookieValue?.split(',') : [];
  };

  getShareClassCookieId = (fundId: number, shareClassId: string): string => {
    if (shareClassId) {
      return `${fundId}-${shareClassId}`;
    }
    return `${fundId}-`;
  };

  private update(): void {
    const scroll = window.pageYOffset;

    if (scroll < this.deltaHeight) {
      this.progress = scroll < 0 ? 0 : scroll / this.deltaHeight;
      this.reversed = true;
    } else {
      this.progress = 1;
      this.reversed = false;
    }

    this.headerAnimation.progress(this.progress);
    this.shadowAnimation.reversed(this.reversed);
    this.requestId = null;
    this.saveFundButtonText = this.isFundOnSaveFundList() ? 'Fund Saved' : 'Save Fund';
    this.iconColor = this.isFundOnSaveFundList() ? 'white' : '#231F20';
  }

  private hasScrolled(): void {
    const nav = document.querySelector('.globalHeader') as HTMLElement;
    const navHeight = nav ? getFullHeight(nav) : 0;
    const offset = 0;
    const currScroll = this.getWindowScrollTop();

    function translateNavigationBar(yTranslation: number): void {
      if (nav != null) {
        nav.style.transform = `translateY(${yTranslation}px)`;
      }
    }

    if (Math.abs(this.lastScrollTop - currScroll) <= offset) {
      return;
    }

    if (currScroll >= this.lastScrollTop) {
      this.scrollDirectionDown = true;
      this.shrinkNavBar = true;
      translateNavigationBar(-navHeight);
    } else if (currScroll + window.outerHeight < document.body.scrollHeight) {
      translateNavigationBar(0);
      this.shrinkNavBar = false;
      this.scrollDirectionDown = false;
    }
    this.lastScrollTop = currScroll;
  }

  private highlightNavigation(): void {
    function findCurrentlyBrowsedSection(
      anchors: FundLandingPageHeaderPanelNavigation[],
      allAnchors: Element[]
    ) {
      const reversedAnchors = anchors.slice().reverse();
      const lastBannerAnchor = reversedAnchors[0].hrefAnchor;
      const lastBannerAnchorIndex = allAnchors.findIndex(
        (a) => a.firstElementChild.id === lastBannerAnchor
      );
      const firstNonBannerAnchor = allAnchors[lastBannerAnchorIndex + 1];
      if (firstNonBannerAnchor && firstNonBannerAnchor.getBoundingClientRect().top > 200) {
        return reversedAnchors.find((anchor: FundLandingPageHeaderPanelNavigation) => {
          const element = document.getElementById(anchor.hrefAnchor);
          return element != null && element.getBoundingClientRect().top < 50;
        });
      }
      return undefined;
    }

    this.selectedAnchorIdentifier = findCurrentlyBrowsedSection(
      this.mainNavigation,
      this.allAnchorElements
    )?.hrefAnchor;
    this.selectedMobileAnchorIdentifier = findCurrentlyBrowsedSection(
      this.sortedMobileNavigation,
      this.allAnchorElements
    )?.hrefAnchor;
  }

  private getWindowScrollTop(): number {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    if (supportPageOffset) return window.pageYOffset;
    return isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
  }

  private createHeaderAnimation() {
    return gsap
      .timeline({ paused: true })
      .to(this.header, { duration: 1, y: -this.deltaHeight }, 0)
      .to(this.toolbar, { duration: 1, y: this.deltaHeight }, 0)
      .to(this.bgBack, { duration: 1, y: this.deltaHeight / 2 }, 0)
      .to(this.bgFront, { duration: 1, y: this.deltaHeight / 2 }, 0)
      .to(this.bgBack, { duration: 1, alpha: 1 }, 0)
      .to(this.bgFront, { duration: 1, alpha: 0 }, 0);
  }

  private createShadowAnimation() {
    return gsap
      .to(this.header, {
        duration: 0.4,
        boxShadow: '0 2px 5px rgba(0,0,0,0.6)',
        ease: Power1.easeInOut,
      })
      .reverse();
  }

  private cleanUrlHash(input: string): string {
    return input.replace(/[^a-zA-Z0-9\s]/g, '').replaceAll(' ', '');
  }

  private handleUrlHashWithNavigation() {
    let urlHash = this.$router.currentRoute.hash;
    let hash = urlHash.replace(/[^a-zA-Z0-9\s]/g, '');

    if (hash) {
      let isMobile = this.$store.getters.isMobile;
      let navigationItems: FundLandingPageHeaderPanelNavigation[];

      if (isMobile) {
        navigationItems = this.mobileNavigation;
      } else {
        navigationItems = this.mainNavigation;
      }

      let navigationItem = navigationItems.find(
        (n) => hash === this.cleanUrlHash(n.navigationText)
      );

      if (navigationItem) {
        this.selectedAnchorIdentifier = navigationItem.hrefAnchor;
        const panel = document.getElementById(navigationItem.hrefAnchor);
        if (panel != null) {
          window.scrollTo(0, 0);
          gsap.to(window, { duration: 0.0001, scrollTo: panel });
        }
      } else {
        this.$router.push({ hash: '' });
      }
    }
  }
}
