import classnames from 'classnames/bind'
import { useEffect } from 'react'
import { BG_SELF_ATTESTATION_COOKIE_NAME } from '../../constants/COOKIES'
import { Breadcrumb } from '@/shared/MwsViewModels'
import {
  getCookieValue,
  setSelfAttestationCookie,
} from '../../utils/helpers'
import Modal from '../Modal'
import RichText from '../RichText'
import StyledButton from '../StyledButton'
import * as styles from './ModalSelfAttestation.module.scss'
import React from 'react';

const cx = classnames.bind(styles)

export interface ISelfAttestation {
  showSelfAttestation: boolean
  acceptLabel: string
  declineLabel: string
  description: string
  pageName: string
  pageType: string
  pageId: number
  breadcrumbLink: Breadcrumb
}

export type SelfAttestationData = ISelfAttestation | null

interface ISelfAttestationProps {
  selfAttestationContent: ISelfAttestation
  closeModal: () => void
}

type pageIdTransformationType = string | number

const ModalSelfAttestation = ({
  selfAttestationContent,
  closeModal,
}: ISelfAttestationProps) => {

  const acceptLabelTemplate = selfAttestationContent.acceptLabel?.replace(
    '{{FundOrStrategy}}',
    selfAttestationContent.pageType
  )
  const declineLabelTemplate = selfAttestationContent.declineLabel?.replace(
    '{{FundOrStrategy}}',
    selfAttestationContent.pageType
  )
  const descriptionTemplate = selfAttestationContent.description?.replaceAll(
    '{{FundOrStrategy}}',
    selfAttestationContent.pageType
  )

  const pageUrl = window.location.pathname;

  const pathForParentLink = pageUrl?.split('/').filter(Boolean).slice(0, -1)

  const parentLink = `/${pathForParentLink?.join('/')}/`

  function handleDeclineClick() {
    if (parentLink) {
      window.location.pathname = parentLink
    }
  }

  function handleAcceptClick() {
    const COOKIE_ATTESTATION =
      getCookieValue(BG_SELF_ATTESTATION_COOKIE_NAME) || ''

    const attestationEntities: pageIdTransformationType[] =
      COOKIE_ATTESTATION?.split(',')
        .map((element) => parseInt(element))
        .filter(Boolean)
        .filter((itemId) => itemId !== selfAttestationContent.pageId)

     attestationEntities.push(selfAttestationContent.pageId)

     const cookieValue = attestationEntities.join(',')

     const pathNameToSection = parentLink

    setSelfAttestationCookie(
      BG_SELF_ATTESTATION_COOKIE_NAME,
      cookieValue,
      pathNameToSection
    )
    document.getElementsByTagName('body')[0].style.overflow = 'visible';
    closeModal()
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }, [])

  return (
    <Modal>
      <Modal.Title>{selfAttestationContent.pageName}</Modal.Title>
      {descriptionTemplate && (
        <Modal.Body>
          <RichText copy={descriptionTemplate} />
        </Modal.Body>
      )}
      <Modal.Footer>
        <div className={cx('modal-buttonGroup')}>
          <StyledButton
            className={cx('modal-button')}
            type="pillReverse"
            callback={handleAcceptClick}
          >
            {acceptLabelTemplate}
          </StyledButton>
          <StyledButton
            callback={handleDeclineClick}
            className={cx('modal-button')}
            type="pill"
          >
            {declineLabelTemplate}
          </StyledButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSelfAttestation